import { colors } from "@/shared/helpers";
import { useGetCurrentReminderQuery } from "@/shared/lib/graphql";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { ReminderNotification } from "../ui/ReminderNotification";
import moment from "moment";

export function useReminderNotification() {
  const { data: reminders, refetch } = useGetCurrentReminderQuery();

  const reminderNotify = (reminder) => {
    const handleDeleteNotification = () => {
      toast.dismiss(reminder.id);
    };

    return toast(
      <ReminderNotification
        id={reminder.id}
        title={reminder.title}
        remindDate={reminder.remindDate}
        comment={reminder.comment}
        leadId={reminder.leadId}
        onDismiss={handleDeleteNotification}
      />,
      {
        toastId: reminder.id,
        autoClose: false,
        closeOnClick: false,
        style: {
          boxShadow: "4px 4px 4px 0px #00000040",
          backgroundColor: colors.white,
          borderLeft: `2px solid ${colors.brandBlue}`,
        },
      }
    );
  };

  useEffect(() => {
    if (!("Notification" in window)) return;
    if (Notification.permission === "default") {
      Notification.requestPermission();
    }

    const interval = setInterval(() => {
      refetch();
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (reminders?.getCurrentReminder && reminders?.getCurrentReminder?.length > 0) {
      reminders?.getCurrentReminder.forEach((el) => {
        reminderNotify(el);
        if (!("Notification" in window)) return;
        if (Notification.permission === "granted") {
          const notifyDateString = moment(el.remindDate).format("dddd, DD MMMM, HH:mm");
          new Notification(el.title ?? "", {
            body: `${notifyDateString}\n${el.comment ?? ""}`,
            icon: "/favicons/favicon.ico",
            tag: String(el.id),
          });
        }
      });
    }
  }, [reminders?.getCurrentReminder]);
}

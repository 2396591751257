import { GetAttachmentsQuery } from "@/shared/lib/graphql";
import { useAppearanceDelay } from "@/shared/lib/react";
import { Loader, Modal, ModalBody, ModalFooter, ModalTop } from "@/shared/ui";
import { Button } from "@yamaha-admin-sb/button";
import { IconButton } from "@yamaha-admin-sb/icon-button";
import type { FC } from "react";
import styled from "styled-components";

const StyledImages = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 24px;
`;

const StyledImagePreview = styled.div`
  padding: 4px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 94px;
  height: 94px;
  border-radius: 12px;
  img {
    display: block;
    object-fit: cover;
    position: absolute;
    top: 50%;
    left: 50%;
    width: auto;
    height: 100%;

    transform: translate(-50%, -50%);
  }
  border: 2px solid var(--color-white);
  &:hover {
    border: 2px solid var(--color-blue-200);
  }
`;

const StyledDelete = styled.div`
  position: absolute;
  right: 0;
  top: 0;
`;

const LoaderWrapper = styled.div<{ fullWidth: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "94px")};
  height: 94px;
`;

const StyledPlaceholder = styled.p`
  text-align: center;
  width: 100%;
`;

interface IModalReportGalleryProps {
  gallery: GetAttachmentsQuery["getAttachments"];
  onClose: () => void;
  onItemClick: (index: number) => void;
  onItemDelete: (id: number) => void;
  onDownload: () => void;
  isUploading?: boolean;
  isUpdating?: boolean;
  isDisabled?: boolean;
  isRemoving?: boolean;
}

export const ModalReportGallery: FC<IModalReportGalleryProps> = ({
  gallery,
  onClose,
  onItemClick,
  onItemDelete,
  onDownload,
  isUploading,
  isDisabled,
  isUpdating,
  isRemoving,
}) => {
  // используется для того, чтобы placeholder не моргал при быстром изменении состояния загрузок
  const isNotUpdation = gallery.length === 0 && !isUploading && !isUpdating;
  const showPlaceholder = useAppearanceDelay(isNotUpdation);

  return (
    <Modal name="modalReportGallery" onClose={onClose}>
      <ModalTop title="Галерея" onClose={onClose} />
      <ModalBody>
        <StyledImages>
          {showPlaceholder && <StyledPlaceholder>Нет фото по данной категории</StyledPlaceholder>}
          {gallery.map((item, index) => (
            <StyledImagePreview
              key={item.id}
              onClick={(e) => {
                e.stopPropagation();
                onItemClick(index);
              }}
            >
              <img alt={item.fileName || ""} src={item.fileUrl || ""} />
              <StyledDelete
                onClick={(e) => {
                  e.stopPropagation();
                  onItemDelete(item.id);
                }}
              >
                <IconButton icon="cross" size="s" color="gray" />
              </StyledDelete>
            </StyledImagePreview>
          ))}
          {(isUploading || isUpdating) && !isRemoving && (
            <LoaderWrapper fullWidth={gallery.length === 0}>
              <Loader size="l" />
            </LoaderWrapper>
          )}
        </StyledImages>
      </ModalBody>
      <ModalFooter>
        <Button
          leftIcon={true}
          icon="upload"
          isBlock={false}
          isDisabled={isUploading || isDisabled}
          onClick={onDownload}
        >
          Загрузить фото
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Закрыть
        </Button>
      </ModalFooter>
    </Modal>
  );
};

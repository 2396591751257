import React, { useContext, useEffect } from "react";
import { createPortal } from "react-dom";
import styled from "styled-components";
import { IModalContextValue, ModalContext } from "@/shared/lib/react";
import { media } from "@/shared/helpers";

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  display: flex;
`;

const StyledOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.7);
`;

const StyledDialog = styled.div`
  position: relative;
  align-self: center;
  display: flex;
  flex-direction: column;
  background-color: var(--color-white);
  width: 100vw;
  height: 100dvh;
  ${media.tablet} {
    margin: 0 auto;
    height: auto;
    width: 592px;
    border-radius: 12px;
  }
`;

export const StyledBottomButtonsWrap = styled.div`
  display: grid;
  grid-auto-columns: min-content;
  grid-auto-flow: column;
  gap: 12px;
  align-self: center;
`;

export interface IModal {
  children: JSX.Element | JSX.Element[];
  name: keyof IModalContextValue;
  className?: string;
  style?: React.CSSProperties;
  overlayStyle?: React.CSSProperties;
  hasClose?: boolean;
  onClose?: () => void;
}

export const Modal: React.FC<IModal> = (props): JSX.Element => {
  const { children, className, name, hasClose, onClose, style, overlayStyle } = props;
  const [modalContext, setModalContext = () => ({})] = useContext(ModalContext);

  const handleClose = () => {
    setModalContext((prevState) => ({
      ...prevState,
      [name]: false,
    }));
  };

  useEffect(() => {
    if (typeof window === "undefined") return;

    const handleEscClick = (event: KeyboardEvent) => {
      if (event.code === "Escape") handleClose();
    };

    document.addEventListener("keydown", handleEscClick);

    return () => {
      document.removeEventListener("keydown", handleEscClick);
      setModalContext((prevState) => ({ ...prevState, payload: null }));
    };
  }, []);

  return modalContext && modalContext[name] ? (
    createPortal(
      <StyledModal>
        <StyledOverlay style={overlayStyle} onClick={() => (onClose ? onClose() : handleClose())} />
        <StyledDialog className={className} style={style}>
          {children}
        </StyledDialog>
      </StyledModal>,
      document.body
    )
  ) : (
    <></>
  );
};
